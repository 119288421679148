import { useState, useEffect } from "react";
import { Container, Stack, Button, Accordion, Card, Badge, Row } from "react-bootstrap";
import QRCode from "react-qr-code";
import { format } from "date-fns"

function AppList() {
    const [appsData, setAppsData] = useState(null);
    function loadApps() {
        setAppsData(null);
        fetch(process.env.REACT_APP_BASE_URL + "/apps")
            .then((response) => {
                console.log(response);
                return response.json();
            })
            .then((json) => setAppsData(json));
    }

    function parseDate(dateString) {
        var date = new Date(dateString);
        return format(date, "dd/MM/yyyy h:mm a");
    }

    function expandManifestPath(manifestPath) {
        return "itms-services://?action=download-manifest&url=https://" + window.location.hostname + "/" + manifestPath;
    }

    useEffect(() => {
        loadApps();
    }, []);

    return (
        <Container>
            <Stack direction="vertical">
                <Stack direction="horizontal" className="mt-3 mb-3">
                    <h1>Available Apps</h1>
                </Stack>

                <Row>
                    {appsData?.map(({ id, bundleName, created, bundleVersion, manifestPath }) => (
                        <div className="col-md-4 mb-3" key={id}>
                            <Card>
                                <Card.Header>
                                    <Stack direction="horizontal" className="d-flex justify-content-between">
                                        <strong>{bundleName}</strong>
                                        <Button href={expandManifestPath(manifestPath)}>Install</Button>
                                    </Stack>
                                </Card.Header>

                                <Card.Body>
                                    <Accordion>
                                        <Accordion.Item eventKey={id}>
                                            <Accordion.Header>
                                                QR Code
                                            </Accordion.Header>

                                            <Accordion.Body className="d-flex justify-content-center">
                                                <QRCode value={expandManifestPath(manifestPath)} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card.Body>

                                <Card.Footer className="d-flex justify-content-between">
                                    <Badge pill bg="secondary">{parseDate(created)}</Badge>
                                    <Badge pill bg="secondary">{bundleVersion}</Badge>
                                </Card.Footer>
                            </Card>
                        </div>
                    ))}
                </Row>
            </Stack>
        </Container>
    );
}

export default AppList;
